import * as React from 'react'

import {
  BannerPage,
  EpisodeList,
  ContinentsList,
  Container,
  Platforms,
  Seo,
} from '../components/index'
import { usePodcastsList } from '../hooks'

const Episodes: React.FC = () => {
  const podcastList = usePodcastsList()

  const title = 'All Episodes'
  const description =
    'Listen to the latests episodes from web enthusiasts from all over the world. You can also filter by continents to more targeted episodes.'
  return (
    <>
      <Seo title={title} description={description} pathname="episodes/" isLanding />

      <BannerPage
        title={title}
        description={description}
        component={
          <div className="uppercase">
            Also listen on <Platforms />
          </div>
        }
      />

      <h2 className="sr-only">Episodes by continents</h2>
      <ContinentsList />

      <Container className="pt-10">
        <h2 className="text-2xl font-medium leading-8 mb-7 sm:leading-10 sm:text-4xl text-gray-900">
          Latests Episodes
        </h2>
        <EpisodeList podcasts={podcastList?.podcastEpisodes} />
      </Container>
    </>
  )
}

export default Episodes
